<template>
  <v-container fluid>
    <v-row class="mt-6 px-5">
      <v-col xs="12" lg="4">
        <h1 class="secondary--text">Layout Importação</h1>
      </v-col>
    </v-row>

    <v-row class="px-8 mt-5 justify-space-between">
      <v-col class="d-flex align-end pl-0" cols="12" md="8" lg="6" xl="5">
        <v-btn :disabled="disableButtonFinancialGroup" @click="selectTypeLayoutUser('financialGroup')" :width="100" :class="typeSelected === 'financialGroup' ? 'primary' : ''">
          Cliente
        </v-btn>
        <v-btn :disabled="disableButtonInsuranceCarrier" @click="selectTypeLayoutUser('insuranceCarrier')" :width="100" :class="typeSelected === 'insuranceCarrier' ? 'ml-5 px-4 primary' : 'ml-5 px-4'">
          Operadora
        </v-btn>
        <v-col class="pa-0" lg="6">
          <v-autocomplete
            v-if="typeSelected === 'financialGroup'"
            class="ml-10"
            dense
            v-model="financialGroupId"
            :items="financialGroups"
            :loading="loadingFinancialGroups"
            :disabled="disableAutoCompleteFinancialGroup"
            item-value="id"
            item-text="name"
            @input="getLayoutsDataByFinancialGroup('filter')"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            append-icon="fas fa-chevron-down"
            label="Grupo Financeiro"
            placeholder="Selecione o grupo financeiro'"
          />
          <v-autocomplete
            v-if="typeSelected === 'insuranceCarrier'"
            class="ml-10"
            dense
            v-model="insuranceCarrierId"
            :items="insuranceCarriers"
            :loading="loadingInsuranceCarriers"
            :disabled="disableAutoCompleteInsuranceCarrier"
            item-value="id"
            item-text="xipp_commercial_name"
            @input="getLayoutsDataByInsuranceCarrier('filter')"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            append-icon="fas fa-chevron-down"
            label="Operadora"
            placeholder="Selecione a Operadora"
          />
        </v-col>
      </v-col>
      <v-col lg="6" align="end" class="px-0">
        <v-btn class="float-right" :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false" color="primary" @click="setLayoutTypeInSession('isNew')" min-height="50" :disabled="financialGroupId || insuranceCarrierId ? false : true">
          <v-icon style="font-size: 17px;" class="mr-2">fa-cog</v-icon>Configurar Layout
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="px-5">
      <v-col cols="12">
        <v-data-table
          :headers="typeSelected === 'financialGroup' ? tableHeaderFinancialGroups : tableHeaderInsuranceCarriers"
          :items="layoutImports"
          :page.sync="page"
          :items-per-page="10"
          hide-default-footer
          :loading="loadingLayoutImport"
          item-key="id"
          class="elevation-1"
          sort-by="action-active"
        >
          <template v-slot:no-data>
            <span>Nenhum item foi encontrado.</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
             <span style="white-space: nowrap;">{{ item.name ? item.name : '-' }}</span>
          </template>
          <template v-slot:[`item.insuranceCarrierName`]="{ item }">
             <span style="white-space: nowrap;">{{ item.insuranceCarrierName ? item.insuranceCarrierName : '-' }}</span>
          </template>
          <template v-slot:[`item.fileType`]="{ item }">
            <span style="white-space: nowrap;">{{ item.fileType === 'XLS' ? 'XLSX' : item.fileType }}</span>
          </template>
          <template v-slot:[`item.layoutType`]="{ item }">
            {{ item.layoutType }}
          </template>
          <template v-slot:[`item.typeIntegrationValue`]="{ item }">
            {{ item.typeIntegrationValue === 'RECEIVEMENT' ? 'Recebimento' : 'Envio'}}
          </template>
          <template v-slot:[`item.financialGroupName`]="{ item }">
            {{ item.financialGroupName ? item.financialGroupName : '-' }}
          </template>
          <template v-slot:[`item.contractName`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.contractName ? item.contractName : '-' }}
            </span>
          </template>
          <template v-slot:[`item.subContractName`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.subContractName ? item.subContractName : '-' }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu open-on-click bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <div class="menu">
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="editOrCopyLayout(item, 'isEdit')">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="editOrCopyLayout(item, 'isCopy')">
                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Copiar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </template>
          <template v-slot:[`item.action-active`]="{ item }">
            <v-btn
              small
              depressed
              @click="updateLayoutStatus(item.id, item.status)"
              :color="item.status === 'ENABLED' ? '#d7d7d7' : '#43aa8b'"
              :dark="item.status !== 'ENABLED'"
            >
              {{ item.status === 'DISABLED' ? 'Utilizar' : 'Desativar' }}
              <v-icon small right>
                {{item.status === 'DISABLED' ? 'mdi-plus' : 'mdi-close'}}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            :total-visible="layoutImports && layoutImports.length > 0 ? '9' : '0'"
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :disabled="loadingLayoutImport"
            @input="changePagination()"
          />
        </div>
      </v-col>

      <v-col class="text-center">
        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-col>
      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import LayoutService from '@/services-http/sdi/LayoutService';
import LayoutColumnService from '@/services-http/sdi/LayoutColumnService';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import { mapGetters, mapMutations } from 'vuex';

export default ({
  data: () => ({
    // layout: {},
    overlay: false,
    page: 1,
    totalPages: 0,
    typeSelected: 'financialGroup',
    financialGroupId: null,
    insuranceCarrierId: null,
    disableAutoCompleteFinancialGroup: true,
    disableAutoCompleteInsuranceCarrier: false,
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingLayoutImport: false,
    disableButtonFinancialGroup: false,
    disableButtonInsuranceCarrier: false,
    financialGroups: [],
    insuranceCarriers: [],
    layoutImports: [],
    queryString: { isModel: false },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    tableHeaderFinancialGroups: [
      { text: 'Nome', align: 'start', value: 'name', sortable: false },
      { text: 'Tipo de Arquivo', align: 'start', value: 'fileType', sortable: false },
      { text: 'Tipo de Layout', align: 'start', value: 'layoutType', sortable: false },
      { text: 'Tipo de Integração', value: 'typeIntegrationValue', align: 'start', sortable: false },
      { text: 'Grupo Financeiro', align: 'start', value: 'financialGroupName', sortable: false },
      { text: 'Contrato', align: 'start', value: 'contractName', sortable: false },
      { text: 'Sub-Contrato', align: 'start', value: 'subContractName', sortable: false },
      { text: 'Ações', align: 'start', value: 'actions', sortable: false },
      { text: '', value: 'action-active', sortable: false },
    ],
    tableHeaderInsuranceCarriers: [
      { text: 'Nome', align: 'start', value: 'name', sortable: false },
      { text: 'Tipo de Arquivo', align: 'start', value: 'fileType', sortable: false },
      { text: 'Tipo de Layout', align: 'start', value: 'layoutType', sortable: false },
      { text: 'Operadora', align: 'start', value: 'insuranceCarrierName', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'action-active', sortable: false },
    ],
  }),

  mixins: [
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
  ],

  async mounted() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData()
    await this.getFinancialGroups();
    this.removeLayoutInSessionStorage();
    this.checkTypeLayout();
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },

  methods: {
    ...mapMutations({
      resetLayoutImportModule: 'layout-import/resetModule',
      setLayoutColumns: 'layout-import/setLayoutColumns',
      setLayoutType: 'layout-import/setLayoutType',
      setLayouts: 'layout-import/setLayouts',
      setLayout: 'layout-import/setLayout',
    }),
    handlePageRefresh() {
      if (Object.keys(this.layout) > 0) {
        sessionStorage.setItem('layout', JSON.stringify(this.layout))
      }
      if (Object.keys(this.layoutType).length > 0) {
        sessionStorage.setItem('layoutType', JSON.stringify(this.layoutType))
      }
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('layout')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('layout')));
        sessionStorage.removeItem('layout')
      }
      if (sessionStorage.getItem('layoutType')) {
        this.setLayoutType(JSON.parse(sessionStorage.getItem('layoutType')));
        sessionStorage.removeItem('layoutType')
      }
    },
    async checkFinancialGroup() {
      if (this.$route.query.financialGroupId) {
        this.financialGroupId = Number(this.$route.query.financialGroupId);
        await this.getLayoutsDataByFinancialGroup('filter');
      } else {
        this.financialGroupId = '';
        this.disableAutoCompleteFinancialGroup = false;
      }
    },
    checkTypeLayout() {
      if (Object.keys(this.layoutType).length > 0 && !this.$route.query.financialGroupId) {
        // this.layoutType = JSON.parse(sessionStorage.getItem('layoutType'));
        if (this.layoutType.financialGroupId) {
          this.financialGroupId = this.layoutType.financialGroupId;
          this.getLayoutsDataByFinancialGroup();
        } else {
          this.insuranceCarrierId = this.layoutType.insuranceCarrierId;
          this.typeSelected = 'insuranceCarrier';
          this.getInsuranceCarriers();
          this.getLayoutsDataByInsuranceCarrier();
        }
      } else {
        this.setLayoutType({});
        // sessionStorage.removeItem('layoutType');
        this.checkFinancialGroup();
      }
    },
    selectTypeLayoutUser(type) {
      this.typeSelected = type;
      // this.layoutImports = [];
      this.setLayouts([]);
      if (this.typeSelected === 'financialGroup') {
        this.getFinancialGroups();
        this.checkFinancialGroup();
      }
      if (this.typeSelected === 'insuranceCarrier') {
        this.getInsuranceCarriers();
        this.insuranceCarrierId = '';
      }
    },
    async getLayoutsDataByFinancialGroup(type) {
      if (type === 'filter') this.page = 1;
      if (this.financialGroupId) {
        this.loadingLayoutImport = true;
        this.disableButtonInsuranceCarrier = true;
        this.disableAutoCompleteFinancialGroup = true;
        this.mountQuery('financialGroup');

        await this.layoutService.FindAllByFilter(this.queryString).then((response) => {
          if (response && response.data && response.data.content) {
            // this.layoutImports = response.data.content;
            this.setLayouts(response.data.content)
            this.layoutImports = [ ...this.layoutsData ];
            this.totalPages = response.data.totalPages;
            this.loadingLayoutImport = false;
            this.disableButtonInsuranceCarrier = false;
            if (!this.$route.query.financialGroupId) {
              this.disableAutoCompleteFinancialGroup = false;
            }
          }
        }).catch(() => {
          this.loadingLayoutImport = false;
          this.disableButtonInsuranceCarrier = false;
          if (!this.$route.query.financialGroupId) {
            this.disableAutoCompleteFinancialGroup = false;
          }
        });
        this.sortTableByStatus();
      }
    },
    async getLayoutsDataByInsuranceCarrier(type) {
      if (type === 'filter') this.page = 1;
      if (this.insuranceCarrierId) {
        this.loadingLayoutImport = true;
        this.disableButtonFinancialGroup = true;
        this.disableAutoCompleteInsuranceCarrier = true;
        this.mountQuery('insuranceCarrier');

        await this.layoutService.FindAllByFilter(this.queryString).then((response) => {
          if (response && response.data && response.data.content) {
            // this.layoutImports = response.data.content;
            this.setLayouts(response.data.content)
            this.layoutImports = [ ...this.layoutsData ];
            this.totalPages = response.data.totalPages;
            this.loadingLayoutImport = false;
            this.disableButtonFinancialGroup = false;
            this.disableAutoCompleteInsuranceCarrier = false;
          }
        }).catch(() => {
          this.loadingLayoutImport = false;
          this.disableButtonFinancialGroup = false;
          this.disableAutoCompleteInsuranceCarrier = false;
        });
        this.sortTableByStatus();
      }
    },
    mountQuery(item) {
      if (item === 'financialGroup') {
        this.queryString.financialGroupId = this.financialGroupId;
        this.queryString.entityType = 'FINANCIAL_GROUP';
        this.queryString.page = this.page - 1;
        this.queryString.size = 10;
      } else {
        this.queryString.insuranceCarrierId = this.insuranceCarrierId;
        this.queryString.entityType = 'INSURANCE_CARRIER';
        this.queryString.page = this.page - 1;
        this.queryString.size = 10;
      }
    },
    sortTableByStatus() {
      this.layoutImports.sort((a, b) => (a.status > b.status ? 1 : -1));
    },
    updateLayoutStatus(id, status) {
      const statusFormated = status === 'ENABLED' ? 'DISABLED' : 'ENABLED';
      this.layoutService.UpdateLayoutStatus(id, statusFormated).then(() => {
        if (this.typeSelected === 'financialGroup') {
          this.getLayoutsDataByFinancialGroup();
        } else {
          this.getLayoutsDataByInsuranceCarrier();
        }
      });
    },
    editOrCopyLayout(item, type) {
      if (item) {
        this.overlay = true;
        // this.layout = item;
        this.setLayout(item)
        const query = `?id_layout=${item.id}`;
        this.layoutColumnService.FindAllByFilter(query).then((response) => {
          if (response && response.data.content.length > 0) {
            this.layout.columns = response.data.content[0].columns;
            sessionStorage.setItem('layout', JSON.stringify(this.layout));
            this.setLayoutColumns(response.data.content[0].columns);
            this.setLayoutTypeInSession(type);
          }
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar as colunas do layout');
        });
      }
    },
    setLayoutTypeInSession(type) {
      const layoutType = {
        entityType: this.queryString.entityType ? this.queryString.entityType : null,
        financialGroups: this.queryString.entityType === 'FINANCIAL_GROUP' && this.financialGroups ? this.financialGroups : [],
        insuranceCarriers: this.queryString.entityType === 'INSURANCE_CARRIER' && this.insuranceCarriers ? this.insuranceCarriers : [],
        financialGroupId: this.queryString.entityType === 'FINANCIAL_GROUP' && this.financialGroupId ? this.financialGroupId : null,
        insuranceCarrierId: this.queryString.entityType === 'INSURANCE_CARRIER' && this.insuranceCarrierId ? this.insuranceCarrierId : null,
        contracts: [],
        subContracts: [],
      };
      sessionStorage.setItem('layoutType', JSON.stringify(layoutType));
      this.setLayoutType(layoutType);

      if (type === 'isNew') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportConfigs' });
      } else if (type === 'isEdit') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnEditConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportColumnEditConfigs' });
      } else if (type === 'isCopy') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnCopyConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportColumnCopyConfigs' });
      }
    },
    removeLayoutInSessionStorage() {
      // sessionStorage.removeItem('layout');
      this.setLayout({})
    },
    onClickCancel() {
      this.resetLayoutImportModule();
      this.$router.push({ name: 'HomeAllMenus' });
    },
    changePagination() {
      if (this.typeSelected === 'financialGroup') {
        this.getLayoutsDataByFinancialGroup();
      } else {
        this.getLayoutsDataByInsuranceCarrier();
      }
    },
  },

  computed: {
    ...mapGetters({
      layoutsData: 'layout-import/layouts',
      layoutType: 'layout-import/layoutType',
      layout: 'layout-import/layout',
    })
  },

  created() {
    this.layoutService = new LayoutService();
    this.layoutColumnService = new LayoutColumnService();
  },
});
</script>
